var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.smInit = smInit;
global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page_home = {
	ready: function(){
		this.letterWrapping();
	},
	load: function(){
		this.homeSlider();
		this.personeAnim();
		this.terreAnim();
		this.famigliaAnim();
		this.galAnim();
		this.vitAnim();
		this.newsAnim();
		this.playVideo();
		this.mapInit();
		this.mapPoints();

		//Restyling
		this.loadVideo();
		this.introAnimRes();
		this.terreAnimRes();
		this.blocksAnimRes();
		this.visualAnimRes();
		this.terreTextAnimRes();
		this.newsAnimRes();
	},
	initResize: function(){
		this.mapPoints();
		this.personeAnim();
	},
	letterWrapping: function(){
		$('.homeVisual__title').html(function (i, html) {
		    var chars = $.trim(html).split("");
		    return '<span>' + chars.join('</span><span>') + '</span>';
		});
	},
	homeSlider: function(){
		jQuery('.homeVisual__slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			// speed: 350,
			speed: 450,
			touch: false,
			autoplay: true,
			autoplaySpeed: 4500,
		});

		$('.homeVisual__thumb').on('click', function(){
			siteGlobal.lazyLoad();
			var slideNum = $(this).data('thumb');
			$('.homeVisual__thumb').removeClass('slick-current');
			$(this).addClass('slick-current');
			jQuery('.homeVisual__slider').slick('slickGoTo', slideNum);
		})

		jQuery('.homeVisual__slider').on('beforeChange', function(){
			$('.homeVisual__title').addClass('changingTitle');
			siteGlobal.lazyLoad();
		});
		jQuery('.homeVisual__slider').on('afterChange', function(){
			var currentSlide = jQuery('.homeVisual__slider').slick('slickCurrentSlide');
			$('.homeVisual__title').removeClass('changingTitle');
			$('.homeVisual__thumb').removeClass('slick-current');
			$('.homeVisual__thumb[data-thumb="'+currentSlide+'"]').addClass('slick-current');

		});
	},
	personeAnim: function(){
		if ($(window).outerWidth(true) < 1700) {
			var controller = new smInit.Controller(),
				tl = new tmInit(),
				persLab = $('.homePers .homeSec__label'),
				persEl = $('.homePers .homeSec__textWrap > *'),
				persImg = $('.homePers__img'),
				tween = tl.to(persLab , .6, {left:0, opacity:1}, 'final')
						  .staggerTo(persEl , .4, {top:0, opacity:1}, .18, "-=.4")
						  .to(persImg , .5, {left:"50%", opacity:1}, "-=.6"),
				scene = new smInit.Scene({reverse: true, triggerElement: $('#pers_trig')[0]}).setTween(tween).addTo(controller);
		} else {
			var controller = new smInit.Controller(),
				tl = new tmInit(),
				persLab = $('.homePers .homeSec__label'),
				persEl = $('.homePers .homeSec__textWrap > *'),
				persImg = $('.homePers__img'),
				tween = tl.to(persLab , .6, {left:0, opacity:1}, 'final')
						  .staggerTo(persEl , .4, {top:0, opacity:1}, .18, "-=.4")
						  .to(persImg , .5, {left:"0", opacity:1}, "-=.6"),
				scene = new smInit.Scene({reverse: true, triggerElement: $('#pers_trig')[0]}).setTween(tween).addTo(controller);
		}
	},
	terreAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			terreLab = $('.homeTerre .homeSec__label'),
			terreEl = $('.homeTerre .homeSec__textWrap > *'),
			terreMap = $('.homeTerre .homeTerre__map'),

			tween = tl.to(terreLab , .6, {left:0, opacity:1}, 'final', "+=.2")
					  .staggerTo(terreEl , .4, {top:0, opacity:1}, .18, "-=.5")
					  .to(terreMap , .6, {bottom:0}, 'final', "+=.2"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#terre_trig')[0]}).setTween(tween).addTo(controller);
	},
	famigliaAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			famTit = $('.homeFam__title'),
			famImg = $('.homeFam__imgWrap'),

			tween = tl.to(famTit , .6, {left:0, opacity:1}, 'final')
					  .staggerTo(famImg , .4, {top:0, opacity:1}, .18, "-=.4"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#homeFam__trig')[0]}).setTween(tween).addTo(controller);
	},
	galAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			gall = $('.homeFam__gallItem'),

			tween = tl.staggerTo(gall , .3, {bottom:0, left:0, opacity:1}, .15),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#homeFam__galTrig')[0]}).setTween(tween).addTo(controller);
	},
	vitAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			wave = $('.homeVit__wave'),
			vitTextEl = $('.homeVit__textBlock > *'),

			tween = tl.to(wave, .6, {x:0, opacity:1}, 'final')
					  .staggerTo(vitTextEl , .4, {top:0, opacity:1}, .18, "-=.5"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#homeVit__trig')[0]}).setTween(tween).addTo(controller);
	},
	newsAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			news = $('.news'),

			tween = tl.staggerTo(news , .3, {left:0, bottom:0, opacity:1}, .15, "-=.2"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#homeNews_trig')[0]}).setTween(tween).addTo(controller);
	},
	playVideo: function(){
		var vid = document.getElementById('vitigni_video');
		if (vid) {
			$('.playBtn').on('click', function(){
				vid.play();
				$(this).fadeOut(100);
			})
			$(vid).on('click', function(){
				vid.pause();
				$('.playBtn').fadeIn(100);
			})
		}
	},
	mapInit: function(){
		$('.homeTerre__legendTrig').on('click', function(){
			$('.homeTerre__blocksWrap').slideToggle(250);
			$('.homeTerre__mapLegend').toggleClass('open');
		})
		$('.el__block').on('click', function(){
			var leg = $(this).data('legend');
			$(this).toggleClass('active').find('.el__list').slideToggle(150);

			$('[data-points="'+leg+'"]').toggleClass('active');
		})
	},
	mapPoints: function(){
		var mapHeight = $('img.homeTerre__map').outerHeight(),
			pointsHeight = (mapHeight * 77.4) / 100;
		$('.homeTerre__mapPoints').css('height', pointsHeight);
	},

	//Restyling
	loadVideo: function(){

		let video = document.querySelector('.commonVisual__video');
		let source = document.querySelector('.commonVisual__video source');
		let src = source.getAttribute('data-src');
		video.setAttribute('src', src);

		let portraitVideo = document.querySelector('.commonVisual__video--portrait');
		let portraitSource = document.querySelector('.commonVisual__video--portrait source');
		let portraitSrc = portraitSource.getAttribute('data-src');
		portraitVideo.setAttribute('src', portraitSrc);
		video.play()

		portraitVideo.play()

	},
	terreAnimRes: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			terreLab = $('.homeTerreRestyling__imageMapContent .homeSec__label'),
			terreEl = $('.homeTerreRestyling__imageMapContent .homeSec__textWrap > *'),
			terreMap = $('.homeTerreRestyling__imageMapContent .homeTerre__map'),

			tween = tl.to(terreLab , .6, {left:0, opacity:1}, 'final', "+=.2")
					  .staggerTo(terreEl , .4, {top:0, opacity:1}, .18, "-=.5")
					  .to(terreMap , .6, {bottom:0}, 'final', "+=.2"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('.homeTerreRestyling')[0]}).setTween(tween).addTo(controller);
	},
	visualAnimRes: function(){
		var controller = new smInit.Controller(),
				tl = new tmInit(),
				logo = $('.homeIntroRestyling .logo'),
				description = $('.homeIntroRestyling .textContent'),
				link = $('.homeIntroRestyling .link'),
				farfalle = $('.homeIntroRestyling .farfalle'),

				tween = tl.from(logo , .6, {y:20, opacity:0}, 'final')
				.staggerFrom(description , .4, {y:20, opacity:0}, .18, "-=.1")
				.staggerFrom(link , .5, {y:20, opacity:0}, "-=.1")
				.staggerFrom(farfalle , .5, {opacity:0}, "-=.1"),

		scene = new smInit.Scene({reverse: true, triggerElement: $('.homeIntroRestyling')[0]}).setTween(tween).addTo(controller);
	},
	introAnimRes: function(){
		var controller = new smInit.Controller(),
				tl = new tmInit(),
				text = $('.homeVisualRestyling .textContent p'),

				tween = tl.from(text , .6, {x:'30%', opacity:0}, 'final')
		scene = new smInit.Scene({reverse: true, triggerElement: $('.homeVisualRestyling')[0]}).setTween(tween).addTo(controller);
		console.log(text);
	},
	blocksAnimRes: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			title = $('.homeBlocksRestyling .columnBlocks__block .title'),
			description = $('.homeBlocksRestyling .columnBlocks__block .description'),
			link = $('.homeBlocksRestyling .columnBlocks__block .link'),

			tween = tl.from(title , .6, {y:20, opacity:0}, 'final')
						.staggerFrom(description , .4, {y:20, opacity:0}, .18, "-=.1")
						.staggerFrom(link , .5, {y:20, opacity:0}, "-=.1"),
			scene = new smInit.Scene({reverse: true, triggerElement: $('.homeBlocksRestyling')[0]}).setTween(tween).addTo(controller);
	},
	terreTextAnimRes: function(){
		var controller = new smInit.Controller(),
				tl = new tmInit(),
				title = $('.homeTerreRestyling .title'),
				description = $('.homeTerreRestyling .description'),
				link = $('.homeTerreRestyling .link'),

				tween = tl.from(title , .6, {y:20, opacity:0}, 'final')
								.staggerFrom(description , .4, {y:20, opacity:0}, .18, "-=.1")
								.staggerFrom(link , .5, {y:20, opacity:0}, "-=.1"),
		scene = new smInit.Scene({reverse: true, triggerElement: $('.homeTerreRestyling')[0]}).setTween(tween).addTo(controller);
	},
	newsAnimRes: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			news = $('.commonNewsRow__news'),

			tween = tl.staggerFrom(news , .4, {x:'-20%', opacity:0}, .15, "-=.2"),

			scene = new smInit.Scene({reverse: true, triggerElement: $('.commonNewsRow')[0]}).setTween(tween).addTo(controller);
	},
}

$(document).ready(function(){
	page_home.ready();
})

$(window).resize(function(){
    page_home.initResize();
});

$(window).on('load', function(){
	page_home.load();
})
